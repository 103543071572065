:root {
  --blueColor: rgb(30, 148, 241);
  --redColor: rgb(250, 90, 75);
  --whiteColor: rgb(255, 255, 255);
  --searchBarHeight: 56px;
  --searchBarFontSize: 17px;
  --searchBarSectionMaxHeight: 116px;
  --searchBarSectionMinHeight: 76px;
  --searchBar: 70px;
}

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* background-color: #ECECEC; */
}

/* test */
.testT {
  border: 1px solid teal;
}

.testR {
  border: 1px solid red;
}

.testY {
  border: 1px solid yellow;
}

.testP {
  border: 1px solid purple;
}

.testO {
  border: 1px solid orange;
}

.testG {
  border: 1px solid green;
}

.testBla {
  border: 1px solid black;
}

.testBlu {
  border: 1px solid blue;
}

/* rotate button*/
.rotate-css {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.normal-css {
  animation-timing-function: linear;
  /* -webkit-transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out; */
}

.swap-button {
  position: absolute;
  /* top: 50px;
    margin-left: -20px;
    margin-top: -25px; */
  /* left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto; */
  z-index: 50;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  background-color: var(--whiteColor);
  color: var(--blueColor);
  top: 15px;
  left: -12px;
  padding: 3px;
  border: 0px;
}

.swap-container {
  position: relative;
}

.swap-button:hover {
  /* background-color: var(--whiteColor); */
  /* color: var(--blueColor); */
  transform: scaleXY(1.95) !important;
}

/* root-container */
.root-container {
  height: auto;
  display: flex;
  flex-direction: column;
}

/* search bar */
.search-container {
  /* flex-wrap: wrap; */
  padding: 0.5rem 1rem;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  /* flex-flow: row; */
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /*font-size: var(--searchBarFontSize);
    height: var(--searchBar); */
  /* height: var(--searchBarSectionMinHeight); */
  /* border-color: rgb(36, 2, 2); */
  /* margin: auto 0; */
}

.route-block {
  flex-wrap: wrap;
  display: flex;
  /* flex-flow: row; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: var(--searchBarFontSize);
  height: var(--searchBar);
}

.route-block-route-types {
  display: flex;
  /* flex-flow: row; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* .route-block-route-types div>span:hover {
    background: #ff7875;
} */

.route-block-inputs {
  display: flex;
  /* flex-flow: row; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
}

.route-block-date-dropdown {
  display: flex;
  /* flex-flow: row; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* seems irrelevant css */
/* .flex-container-header {
    margin-top: 50px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
} */

.underline-on-hover:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* dropdown type of shipment */
.container-dropdown {
  height: 53px;
  width: 100%;
  border: 0px solid white;
  /* border-radius: 0px; */
  cursor: pointer;
  font-size: 15px;
  /* margin: 0px;
  padding: 0px; */
}

/* Step */

/* home */
/* background: rgba(30, 149, 242, 0.5); */
.home-search-container {
  position: fixed; 
  z-index: 100;
  padding: 10px;
  width: 100%;
  /* height: var(--searchBarSectionMaxHeight); */
  height: auto;
  /* background: var(--blueColor); */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s linear;
  /* min-height: var(--searchBarSectionMinHeight);
    max-height: var(--searchBarSectionMaxHeight); */
}

.home-result-container-new {
  margin-bottom: 20px;
  /* padding-top: 125px;  */
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
}

.home-result-container {
  margin-bottom: 20px;
  padding-top: 125px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
}

.home-result-header-card-container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

/* for no result */
.no-result {
  /* margin-bottom: 20px; */
  padding-top: 10px;
  /* background: rgb(94, 47, 47); */
}

/* for no-resultcard */
.no-result-card {
  height: 426px;
  width: 100%;
  max-width: 960px;
  border-radius: 5px;
  margin: auto;
  z-index: 50;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
}

.no-result-card-content {
  padding: 75px 0;
}

/* result card footer  */
.on-hover:hover {
  cursor: pointer;
  color: black;
}

.card-footer {
  color: blue;
  width: "120px";
}

/* lcl sliders */
.lcl-slider {
  height: 251px;
  width: 270px;
  border-radius: 5px;
  margin: auto;
  z-index: 50;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  padding: 10px 0px 10px;
  margin-bottom: 15px;
  font-size: 1rem;
  font-weight: 400;
}

.lcl-slider-heading {
  padding: 0px 0px;
  width: 100%;
  max-width: 270px;
}

/* FCL container type  */
.fcl-container-type {
  height: 196px;
  width: 270px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 0px 10px;
  margin-bottom: 15px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

/* spinner */
.spinner {
  position: fixed !important;
  top: 0.1em;
  margin-left: 10px !important;
  margin-top: 10px !important;
  height: 50px;
  left: 0.1em;
  z-index: 200;
}

/* StatusMessage */
.status_message {
  position: fixed !important;
  bottom: 1em;
  margin-left: 10px !important;
  height: auto;
  left: 1em;
  z-index: 200;
}

.status_message-background {
  background: rgba(221, 135, 22, 1);
  border-radius: 4px;
  color: white;
}

.status_message-button {
  padding-top: 4px;
  margin: 0 auto;
  width: auto;
}

.price-header-radio-btn-common {
  width: 50%;
  display: inline-block;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}


/* DASHBOARD */
/* remove close icon of map info window*/
.gm-ui-hover-effect {
  display: none !important;
}