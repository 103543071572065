/* date picker */
.my-calendar-21a3741 .ant-calendar-picker-input.ant-input {
    /* color: blue; */
    height: 53px;
    width: 100%;
    border: 0px solid white;
    border-radius: 0px;
    font-size: 17px;
    padding: 0px 0px 0px 12px;
    margin: 0px;
    cursor: pointer;
}

/* make curor default if isFormSubmitSuccess = true */
.my-calendar-21a3741>div:nth-child(1)>.datepicker-make-cursor-default>div>input {
    cursor: default;
}

.my-calendar-21a3741 .ant-calendar-input-wrap {
    display: none;
}

/* disable border-color */
.my-calendar-21a3741 .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: white;
}

/* drop down */
/* select */
.my-select-21a3741 .ant-select-selection.ant-select-selection--single {
    border-radius: 0px 8px 8px 0px;
    height: 53px;
}

.my-select-21a3741 .ant-select-selection.ant-select-selection--single>div>div>div>div+div {
    margin-top: -5px;
    padding: 4px 5px 5px 14px !important;
}

.my-select-21a3741 .ant-select-selection.ant-select-selection--single>div>div>div>div {
    margin-top: -20px;
}

.my-select-21a3741 .ant-select-selection.ant-select-selection--single[aria-expanded="true"]>div>div>div>div {
    margin-top: -10px;
}

.my-select-21a3741 .ant-select-selection.ant-select-selection--single[aria-expanded="true"]>div>div>div>div+div {
    margin-top: -15px;
}

/* remove border */
.my-select-21a3741 .ant-select-selection.ant-select-selection--single {
    border: 0px solid white;
}

/* make cursor to default if if isFormSubmitSuccess = true */
.my-select-21a3741.select-make-cursor-default .ant-select-selection.ant-select-selection--single {
    cursor: default;
}

/* remove arrow down svg icon if isFormSubmitSuccess = true */
.my-select-21a3741.select-make-cursor-default .ant-select-selection.ant-select-selection--single .ant-select-arrow {
    display: none;
}

.my-select-21a3741 .ant-select-disabled .ant-select-selection {
    background-color: white;
    color: #00000085;
}

/* .my-select-21a3741 .ant-select-selection__rendered {
    padding: 0px;
    margin: 0px;
} */

/* .my-select-21a3741 .ant-select-selection-selected-value {
    height: 53px;
    padding: 0px;
    margin: 0px;
} */

/* .my-select-21a3741 .ant-select-dropdown-menu.ant-select-dropdown-menu-root.ant-select-dropdown-menu-vertical {
    padding: 0px;
    margin: 0px;
} */

/* .my-select-21a3741 .ant-select-dropdown-menu-item {
    padding: 0px;
    margin: 0px;
} */




/* ant lcl slider  */
/* full background transprent track  */
.my-slider-21a3741 .ant-slider-step {
    /* background: #008CED; */
    background: #d4e0e9;
    height: 1px;
}

.my-slider-21a3741 .ant-slider-handle {
    background: #fff;
    border: 2px solid #008ced !important;
    width: 14px;
    height: 14px;
}

/* color upto slider handle */
.my-slider-21a3741 .ant-slider-track {
    background: #008ced;
    height: 3px;
}

.my-slider-21a3741 .ant-slider-rail {
    background-color: transparent !important;
    height: 0px !important;
    /* height: 0px;  */
}

/* for PriceHeaderTab radio group */
.my-radio-21a3741 .ant-radio-button-wrapper:first-child {
    border-radius: 20px 0 0 20px;
    padding: 0 80px;
    font-weight: 600;
    font-size: 13px;
    border-right: 1px solid #eff3f7;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    text-align: center;
    color: #43637c;
}

.my-radio-21a3741 .ant-radio-button-wrapper:last-child {
    border-radius: 0 20px 20px 0;
    padding: 0 80px;
    font-weight: 600;
    font-size: 13px;
    border-left: 1px solid #eff3f7;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    text-align: center;
    color: #43637c;
}

.my-radio-21a3741 .ant-radio-button-wrapper:first-child:hover:not(active),
.my-radio-21a3741 .ant-radio-button-wrapper:last-child:hover:not(active) {
    border: 1px solid #2196f3;
    background-color: #e5f9ff;
    color: #2196f3;
}


/* .ant-radio-group {
    color: red;
} */

.my-btn-danger-21a3741 .ant-btn-danger,
.my-btn-danger-21a3741 .ant-btn-danger:hover,
.my-btn-danger-21a3741 .ant-btn-danger:focus {
    border-color: transparent;
}

/* menu submenu scroll in CustomDropdown searchbar*/
.my-searchbar-menu-submenu.ant-dropdown-menu-submenu-popup {
    /* max-height: 500px; */
    /* max-height: 70vh; */
    /* max-width: 40vw; */

    /* max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden; */
}

/* .my-searchbar-menu-submenu .ant-dropdown-menu-sub li.ant-dropdown-menu-item {
    padding-right: 25px;
} */


.my-searchbar-menu-submenu {
    /* -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc; */
}


/* change background color */
.my-searchbar-edit-form-tooltip {
    /* background: rgba(17, 48, 73, 0.95); */

}

/*view-details pickup-delivery-ers radio-group line  */
.search-radio-group-pde-line.ant-radio-group label>span:nth-child(2) {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/* option name */
.search-radio-group-pde-line.ant-radio-group label>span:nth-child(2)>span:nth-child(1) {
    /* display: flex;
    justify-content: space-between;
    width: 100%; */
    max-width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    /* text-overflow: ellipsis; */
    padding-bottom: -12px;
}

/* when Form is used and box/pallet is used*/
.form-sliders-make-cursor-default .ant-select-selection.ant-select-selection--single {
    cursor: default;
}

.form-sliders-make-cursor-default .ant-select-disabled .ant-select-selection {
    background-color: white;
    color: #00000085;
}

/* when Form is used and box/pallet is used */
.form-input-make-cursor-default .ant-input.ant-input-disabled {
    cursor: default;
    background-color: white;
    color: #00000085;
}

/* remove arrow down svg icon when Form is used and box/pallet is used */
.form-sliders-make-cursor-default .ant-select-selection.ant-select-selection--single .ant-select-arrow {
    display: none;
}

/* making antd list item ocean freight table price smaller */
.ocean-table-price .ant-list-item-meta-content .ant-list-item-meta-title {
    font-weight: bold;
    font-size: 12px;
}

/* Landing page */
/* small dropdown of DatePicker on Top Search bar */

/* calender */
.datepicker-small-dropdown .ant-calendar {
    width: 200px;
    /* margin-top: -100px; */
    /* header text height is 14px */
    margin-top: -14px;

}

/* header */
.datepicker-small-dropdown .ant-calendar-header {
    height: 20px;
    line-height: 20px;
}

.datepicker-small-dropdown .ant-calendar .ant-calendar-my-select a {
    line-height: 20px;
}

.datepicker-small-dropdown .ant-calendar-prev-year-btn,
.datepicker-small-dropdown .ant-calendar-prev-month-btn,
.datepicker-small-dropdown .ant-calendar-next-month-btn,
.datepicker-small-dropdown .ant-calendar-next-year-btn {
    margin-top: -10px;
}


.datepicker-small-dropdown .ant-calendar-panel .ant-calendar-input-wrap {
    display: none;
}

.datepicker-small-dropdown .ant-calendar .ant-calendar-body {
    padding: 0px;
}

.ant-modal-body {
    padding: 0px;
}

.ant-modal-wrap {
    background: #fff;
}

 .ant-modal {
    padding-bottom: 0px;
    margin:0;
}

.ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 100px; 
}

.steps-container {
    display: flex;
    flex-direction: row;
}

@media (max-width: 768px) {
    .steps-container {
        flex-wrap: wrap;
      }
  .ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 80px; }
    .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
        top: 7px;
        margin: 0 0 0 49px;
    }
    .ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        margin-left: 42px;
    }

}

@media (max-width: 576px) {
    .ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
        width: 55px;
    }
    .ant-steps-item-description {
        font-size: 12px;
        font-weight: 600;
    }
     .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
        top: 7px;
        width: 100%;
        /* margin: 0 0 0 26px; */
        margin: 0 0 0 17px;
        padding: 0;
        height: 1px;
    }
    .ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        width: 8px;
        height: 8px;
        /* margin-left: 20px; */
        margin-left: 11px;
        padding-right: 0;
        line-height: 8px;
        background: transparent;
        border: 0;
    }
    .ant-steps-item-title {
        top:-50px;
        font-size: 10px;
        font-weight: bolder;
    }
}



.ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    top: 0;
    left: 0.5px;
    font-size: 10px;
    line-height: 0px;
}

@media only screen and (min-width: 768px) {
    .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
        top: 7px;
        width: 100%;
        /* margin: 0 0 0 74px; */
        margin: 0 0 0 52px;
        padding: 0;
        height: 1px;
    }
    .ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        margin-left: 45px;
    }
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    /* background-color: #1890ff; */
    height: 1px;
}

.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #e8e8e8;
    height: 1px;
}

/* Change the color of the tail of the last step */
.ant-steps-item:nth-last-child(2) > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #e8e8e8;
    height: 1px;
}

/* .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #5dc177;
} */

.ant-steps-item-title {
    top:-40px;
    font-size: 12px;
    font-weight: bold;
}

.custom-modal .ant-modal-wrap {
    background: inherit; /* Use the parent's background color */
  }