@import url(https://fonts.googleapis.com/css?family=Montserrat|Noto+Sans|Roboto&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%;
    /* 1 */
    -webkit-text-size-adjust: 100%;
    /* 2 */
    -ms-text-size-adjust: 100%;
    /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0;
    /* 1 */
    -ms-interpolation-mode: bicubic;
    /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0;
    /* 1 */
    padding: 0;
    white-space: normal;
    /* 2 */
    *margin-left: -7px;
    /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%;
    /* 1 */
    margin: 0;
    /* 2 */
    vertical-align: baseline;
    /* 3 */
    *vertical-align: middle;
    /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"],
/* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    /* 2 */
    cursor: pointer;
    /* 3 */
    *overflow: visible;
    /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */
    *height: 13px;
    /* 3 */
    *width: 13px;
    /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto;
    /* 1 */
    vertical-align: top;
    /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
/* font awesome icons 4.7.0*/

body {
  margin: 0;
  /* background: #ECECEC; */
  background: transparent;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* date picker */
.my-calendar-21a3741 .ant-calendar-picker-input.ant-input {
    /* color: blue; */
    height: 53px;
    width: 100%;
    border: 0px solid white;
    border-radius: 0px;
    font-size: 17px;
    padding: 0px 0px 0px 12px;
    margin: 0px;
    cursor: pointer;
}

/* make curor default if isFormSubmitSuccess = true */
.my-calendar-21a3741>div:nth-child(1)>.datepicker-make-cursor-default>div>input {
    cursor: default;
}

.my-calendar-21a3741 .ant-calendar-input-wrap {
    display: none;
}

/* disable border-color */
.my-calendar-21a3741 .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: white;
}

/* drop down */
/* select */
.my-select-21a3741 .ant-select-selection.ant-select-selection--single {
    border-radius: 0px 8px 8px 0px;
    height: 53px;
}

.my-select-21a3741 .ant-select-selection.ant-select-selection--single>div>div>div>div+div {
    margin-top: -5px;
    padding: 4px 5px 5px 14px !important;
}

.my-select-21a3741 .ant-select-selection.ant-select-selection--single>div>div>div>div {
    margin-top: -20px;
}

.my-select-21a3741 .ant-select-selection.ant-select-selection--single[aria-expanded="true"]>div>div>div>div {
    margin-top: -10px;
}

.my-select-21a3741 .ant-select-selection.ant-select-selection--single[aria-expanded="true"]>div>div>div>div+div {
    margin-top: -15px;
}

/* remove border */
.my-select-21a3741 .ant-select-selection.ant-select-selection--single {
    border: 0px solid white;
}

/* make cursor to default if if isFormSubmitSuccess = true */
.my-select-21a3741.select-make-cursor-default .ant-select-selection.ant-select-selection--single {
    cursor: default;
}

/* remove arrow down svg icon if isFormSubmitSuccess = true */
.my-select-21a3741.select-make-cursor-default .ant-select-selection.ant-select-selection--single .ant-select-arrow {
    display: none;
}

.my-select-21a3741 .ant-select-disabled .ant-select-selection {
    background-color: white;
    color: #00000085;
}

/* .my-select-21a3741 .ant-select-selection__rendered {
    padding: 0px;
    margin: 0px;
} */

/* .my-select-21a3741 .ant-select-selection-selected-value {
    height: 53px;
    padding: 0px;
    margin: 0px;
} */

/* .my-select-21a3741 .ant-select-dropdown-menu.ant-select-dropdown-menu-root.ant-select-dropdown-menu-vertical {
    padding: 0px;
    margin: 0px;
} */

/* .my-select-21a3741 .ant-select-dropdown-menu-item {
    padding: 0px;
    margin: 0px;
} */




/* ant lcl slider  */
/* full background transprent track  */
.my-slider-21a3741 .ant-slider-step {
    /* background: #008CED; */
    background: #d4e0e9;
    height: 1px;
}

.my-slider-21a3741 .ant-slider-handle {
    background: #fff;
    border: 2px solid #008ced !important;
    width: 14px;
    height: 14px;
}

/* color upto slider handle */
.my-slider-21a3741 .ant-slider-track {
    background: #008ced;
    height: 3px;
}

.my-slider-21a3741 .ant-slider-rail {
    background-color: transparent !important;
    height: 0px !important;
    /* height: 0px;  */
}

/* for PriceHeaderTab radio group */
.my-radio-21a3741 .ant-radio-button-wrapper:first-child {
    border-radius: 20px 0 0 20px;
    padding: 0 80px;
    font-weight: 600;
    font-size: 13px;
    border-right: 1px solid #eff3f7;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    text-align: center;
    color: #43637c;
}

.my-radio-21a3741 .ant-radio-button-wrapper:last-child {
    border-radius: 0 20px 20px 0;
    padding: 0 80px;
    font-weight: 600;
    font-size: 13px;
    border-left: 1px solid #eff3f7;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    text-align: center;
    color: #43637c;
}

.my-radio-21a3741 .ant-radio-button-wrapper:first-child:hover:not(active),
.my-radio-21a3741 .ant-radio-button-wrapper:last-child:hover:not(active) {
    border: 1px solid #2196f3;
    background-color: #e5f9ff;
    color: #2196f3;
}


/* .ant-radio-group {
    color: red;
} */

.my-btn-danger-21a3741 .ant-btn-danger,
.my-btn-danger-21a3741 .ant-btn-danger:hover,
.my-btn-danger-21a3741 .ant-btn-danger:focus {
    border-color: transparent;
}

/* menu submenu scroll in CustomDropdown searchbar*/
.my-searchbar-menu-submenu.ant-dropdown-menu-submenu-popup {
    /* max-height: 500px; */
    /* max-height: 70vh; */
    /* max-width: 40vw; */

    /* max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden; */
}

/* .my-searchbar-menu-submenu .ant-dropdown-menu-sub li.ant-dropdown-menu-item {
    padding-right: 25px;
} */


.my-searchbar-menu-submenu {
    /* -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc; */
}


/* change background color */
.my-searchbar-edit-form-tooltip {
    /* background: rgba(17, 48, 73, 0.95); */

}

/*view-details pickup-delivery-ers radio-group line  */
.search-radio-group-pde-line.ant-radio-group label>span:nth-child(2) {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/* option name */
.search-radio-group-pde-line.ant-radio-group label>span:nth-child(2)>span:nth-child(1) {
    /* display: flex;
    justify-content: space-between;
    width: 100%; */
    max-width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    /* text-overflow: ellipsis; */
    padding-bottom: -12px;
}

/* when Form is used and box/pallet is used*/
.form-sliders-make-cursor-default .ant-select-selection.ant-select-selection--single {
    cursor: default;
}

.form-sliders-make-cursor-default .ant-select-disabled .ant-select-selection {
    background-color: white;
    color: #00000085;
}

/* when Form is used and box/pallet is used */
.form-input-make-cursor-default .ant-input.ant-input-disabled {
    cursor: default;
    background-color: white;
    color: #00000085;
}

/* remove arrow down svg icon when Form is used and box/pallet is used */
.form-sliders-make-cursor-default .ant-select-selection.ant-select-selection--single .ant-select-arrow {
    display: none;
}

/* making antd list item ocean freight table price smaller */
.ocean-table-price .ant-list-item-meta-content .ant-list-item-meta-title {
    font-weight: bold;
    font-size: 12px;
}

/* Landing page */
/* small dropdown of DatePicker on Top Search bar */

/* calender */
.datepicker-small-dropdown .ant-calendar {
    width: 200px;
    /* margin-top: -100px; */
    /* header text height is 14px */
    margin-top: -14px;

}

/* header */
.datepicker-small-dropdown .ant-calendar-header {
    height: 20px;
    line-height: 20px;
}

.datepicker-small-dropdown .ant-calendar .ant-calendar-my-select a {
    line-height: 20px;
}

.datepicker-small-dropdown .ant-calendar-prev-year-btn,
.datepicker-small-dropdown .ant-calendar-prev-month-btn,
.datepicker-small-dropdown .ant-calendar-next-month-btn,
.datepicker-small-dropdown .ant-calendar-next-year-btn {
    margin-top: -10px;
}


.datepicker-small-dropdown .ant-calendar-panel .ant-calendar-input-wrap {
    display: none;
}

.datepicker-small-dropdown .ant-calendar .ant-calendar-body {
    padding: 0px;
}

.ant-modal-body {
    padding: 0px;
}

.ant-modal-wrap {
    background: #fff;
}

 .ant-modal {
    padding-bottom: 0px;
    margin:0;
}

.ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 100px; 
}

.steps-container {
    display: flex;
    flex-direction: row;
}

@media (max-width: 768px) {
    .steps-container {
        flex-wrap: wrap;
      }
  .ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 80px; }
    .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
        top: 7px;
        margin: 0 0 0 49px;
    }
    .ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        margin-left: 42px;
    }

}

@media (max-width: 576px) {
    .ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
        width: 55px;
    }
    .ant-steps-item-description {
        font-size: 12px;
        font-weight: 600;
    }
     .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
        top: 7px;
        width: 100%;
        /* margin: 0 0 0 26px; */
        margin: 0 0 0 17px;
        padding: 0;
        height: 1px;
    }
    .ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        width: 8px;
        height: 8px;
        /* margin-left: 20px; */
        margin-left: 11px;
        padding-right: 0;
        line-height: 8px;
        background: transparent;
        border: 0;
    }
    .ant-steps-item-title {
        top:-50px;
        font-size: 10px;
        font-weight: bolder;
    }
}



.ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    top: 0;
    left: 0.5px;
    font-size: 10px;
    line-height: 0px;
}

@media only screen and (min-width: 768px) {
    .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
        top: 7px;
        width: 100%;
        /* margin: 0 0 0 74px; */
        margin: 0 0 0 52px;
        padding: 0;
        height: 1px;
    }
    .ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        margin-left: 45px;
    }
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    /* background-color: #1890ff; */
    height: 1px;
}

.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #e8e8e8;
    height: 1px;
}

/* Change the color of the tail of the last step */
.ant-steps-item:nth-last-child(2) > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #e8e8e8;
    height: 1px;
}

/* .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #5dc177;
} */

.ant-steps-item-title {
    top:-40px;
    font-size: 12px;
    font-weight: bold;
}

.custom-modal .ant-modal-wrap {
    background: inherit; /* Use the parent's background color */
  }
/* tablet */
@media screen and (max-width: 992px) {
    .root-container {
        display: flex;
        flex-direction: column;
    }

    /* search route section + search btn */
    .home-search-container {
        position: fixed !important;
        height: auto !important;
    }

    /* top search bar */
    .search-container {
        flex-wrap: wrap;
    }

    .search-container .top-title {
        display: none;
    }

    /* seacrh bar */
    .route-block-route-types {
        width: 50% !important;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 5px;
    }

    .route-block-route-types div:nth-child(2) span {
        border-radius: 0px 5px 5px 0px !important;
    }

    .route-block-inputs {
        width: 100% !important;
        margin-bottom: 5px;
        margin-right: 5px;
    }

    .route-block-inputs {
        /* border-radius: 5px 0px 0px 5px !important; */
    }

    .route-block-date-dropdown {
        width: 100% !important;
        margin-bottom: 5px;
        margin-right: 5px;
    }

    .route-block-date-dropdown>span>div {}

    /* all route section upto search btn */
    .route-block {
        /* display: block !important; */
        /* height: 100% !important; */

        /* border: 1px solid orange; */
    }

    /* search btn */
    .route-block-btn {
        margin: 0px auto !important;
        /* border: 1px solid red; */
        width: 30%;
        padding: 10px 0;
    }


    /* result */
    .home-result-container {
        padding-top: 225px !important;
        width: 100% !important;

    }

    .home-result-parent {
        flex-direction: column;
        width: 100% !important;

        align-items: center;
    }

    .home-result-side-component {
        margin-right: 0 !important;
    }

    .fcl-container-type {
        width: 100% !important;
    }

    .lcl-slider {
        width: 100% !important;
    }

    /* no-result card */
    .no-result {
        /* padding: 125px 10px 10px 10px !important; */
        padding: 10px 10px 10px 10px !important;
    }

    .no-result-card {
        /* margin-top: 170px !important; */
        margin-top: 10px !important;
        height: auto !important;
    }

    /* no-start card */
    .no-start {
        padding: 20px !important;
        position: absolute !important;
        margin: 0 auto !important;
        /* margin-top: 200px !important; */
        left: 10px;
        top: 100px;
    }

    .no-start-card {
        margin-top: 170px !important;
        height: auto !important;
    }
}

/* @media screen and (max-width: 675px) {
    .main-card {
        width: 100% !important;
        margin: inherit auto !important;
    }
} */

/* shift book-btn below route block 650px*/
@media screen and (max-width: 700px) {

    .home-result-container {
        /* padding-top: 205px !important; */
        padding: 225px 10px 10px 10px !important;
    }

    .home-result-parent {
        /* margin-top: 30px !important; */
        /* background: red; */
    }

    .no-result {
        padding: 10px 10px 10px 10px !important;
    }

    .no-result-card {
        margin-top: 10px !important;
        height: auto !important;
    }

     /* no-start card */
     .no-start {
        padding: 125px 10px 10px 10px !important;
        position: absolute !important;
        margin: 0 auto !important;
        /* margin-top: 200px !important; */
        left: 10px;
        top: 100px;
    }

    .no-start-card {
        margin-top: 70px !important;
        height: auto !important;
    }

    /* result */
    .fcl-container-type {}

    .home-result-parent {}

    .main-card {
        width: 100% !important;
    }

    .block-items-left {
        width: 100% !important;
    }

    .block-items-right {
        width: 100% !important;
    }

    /* for company name block */
    .company-name-block .company-name-iner {
        max-width: 100% !important;
    }
}



/* mobile */
@media screen and (max-width: 576px) {
    .home-result-parent {
        margin-top: -30px !important;
        /* background: red; */
    }

    .home-result-side-component {
        padding-top: 0 !important;
        margin-top: 55px;
    }

    /* top search bar */
    .search-container .top-title {
        display: none;
    }

    .home-search-container {
        position: relative !important;
        height: auto;
    }

    .home-result-container {
        padding-top: 0px !important;
    }

    .route-block-btn .search-btn {
        padding: 0px 5px !important;
    }

    /* view-details extra info, ocean-freight */
    .vd-extra-info {
        display: none;
    }

    /* pickup-delivery-line */

    /*view-details pickup-delivery-ers radio-group line  */
    /* option name */
    .search-radio-group-pde-line.ant-radio-group label>span:nth-child(2)>span:nth-child(1) {
        max-width: 140px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        padding-bottom: -12px;
    }


    /* no-result card */
    .no-result {
        padding: 10px !important;
    }

    .no-result-card {
        margin-top: 20px !important;
        height: auto !important;
    }

    /* no-start card */
    .no-start {
        padding: 20px !important;
        position: absolute !important;
        margin: 0 auto !important;
        /* margin-top: 200px !important; */
        left: 10px;
        top: 250px;
    }

    .no-start-card {
        height: auto !important;
    }
}

@media only screen and (min-width: 992px) {

    .home-result-side-component {
        padding-right: 20px !important;
        margin-top: 55px;
    }
}
.testR {
  border: 1px dotted red;
}

.testO {
  border: 1px dotted orange;
}

.testPur {
  border: 1px dotted rgb(12, 11, 12);
}

.testBlu {
  border: 1px dotted blue;
}

.testY {
  border: 1px dotted yellow;
}

.testBla {
  border: 1px dotted black;
}

.main-card {
  width: 675px;
  height: auto;
  margin: 0px 0px 15px 0px;
  padding: 0px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  background: white;
}

/* **********start of TOP section************** */

/* *********************start of LEFT block in TOP section********************* */
/* company name, chat, route */

.block-items-left {
  flex-grow: 1;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

/* 
company-name-block
*/
.company-name-block {
  border-bottom: 1px solid #eff3f7;
  /* border-right: 1px solid #eff3f7; */
  padding: 15px 0 14px 20px;
  width: 100%;
  height: auto;
  min-height: 80px;
}

.company-info {
  display: block;
  font-weight: 500;
  /* color: #43637c; */
  /* font-size: 14px; */
  /* text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
  /* min-width: 230px; */
  /* max-width: 370px; */
  width: 100%;
}

/* .company-info:hover {
  text-decoration: underline;
} */

.rate-stars {
  width: 85px;
  height: 24px;
  margin-left: 3.5px;
}

/* .my-rate-stars-21a3741 .ant-rate-star.ant-rate-star-full i svg {
  width: 15px;
  height: 20px;
  margin: -3.5px;
}

.my-rate-stars-21a3741 .ant-rate-star.ant-rate-star-full i svg:hover {
  transform: scale(1);
}

.my-rate-stars-21a3741 .ant-rate-star.ant-rate-star-zero i svg {
  width: 15px;
  height: 20px;
  margin: -3.5px;
}

.my-rate-stars-21a3741 .ant-rate-star.ant-rate-star-half.ant-rate-star-active i svg {
  width: 15px;
  height: 20px;
  margin: -3.5px;
} */

/* .open-chat {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #008cde;
  background-color: transparent;
  font-size: 12px;
  font-weight: 600;
  border-radius: 36px;
  text-transform: uppercase;
  color: #008de0;
  padding: 3px 17px;
  transition: color 0.3s;
}

.open-chat:hover {
  background-color: #f0fafe;
  border: 1px solid #aad8ff;
} */

/* 
route-time-block
*/
.route-time-block {
  padding: 10px 20px 26px 20px;
  /* border-right: 1px solid #eff3f7; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.route {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.route .line {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  position: relative;
  margin-bottom: 10px;
}

.route .line > i:first-of-type > i {
  width: 4px;
  height: 4px;
  background-color: #ccc;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2;
}

.route .line > i:last-of-type > i {
  width: 4px;
  height: 4px;
  background-color: #ccc;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2;
}

/* for the popovers */
.ant-popover .ant-popover-content .ant-popover-arrow {
  border-top-color: rgba(17, 48, 73, 0.95);
  border-right-color: rgba(17, 48, 73, 0.95);
  border-bottom-color: rgba(17, 48, 73, 0.95);
  border-left-color: rgba(17, 48, 73, 0.95);
}

.ant-popover .ant-popover-content .ant-popover-inner {
  background: rgba(17, 48, 73, 0.95);
}

.ant-popover .ant-popover-content .ant-popover-inner .ant-popover-title {
  color: white;
  border-bottom: 1px solid rgba(196, 205, 212, 0.2);
}

.ant-popover
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content {
  color: white;
}

/* 
block-left
*/
.block-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.block-left {
  flex: 1 1 125px;
  text-align: left;
}

.block-left .route-icon {
  position: relative;
  text-align: center;
}

.block-truck.active {
  /* background-color: #31b336 !important; */
}

.block-left .line > i:first-of-type {
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  top: -7px;
  left: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.block-left .line > i:first-of-type {
  /* border: 1px solid #31b336 !important; */
}

.block-left .line > i:last-of-type {
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  top: -7px;
  right: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.selected-port {
  /* background-color: #008ced !important; */
}

/* .selected-port:hover {
  background: attr(data-color) !important;
} */
.block-left .port-line .port-right {
  position: absolute;
  bottom: -15px;
  right: -53px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
  white-space: nowrap;
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #43637c;
}

/* 
block-center
*/
.block-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.block-center {
  flex: 1 1 150px;
  text-align: center;
}

.block-center .route-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

/* 
block-right
*/

.block-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.block-right {
  flex: 1 1 125px;
  text-align: right;
}

.block-right .route-icon {
  position: relative;
  text-align: center;
}

.block-right .block-truck.active {
  /* background-color: #31b336 !important; */
}

.block-right .line > i:first-of-type {
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  top: -7px;
  left: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.block-right .line > i:first-of-type {
  /* border: 1px solid #008ced !important; */
}

.block-right .line > i:last-of-type {
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  top: -7px;
  right: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.block-right .line > i:last-of-type {
  /* border: 1px solid #31b336 !important; */
}

.block-right .selected-port {
  /* background-color: #008ced !important; */
}

.block-right .port-line .port {
  position: absolute;
  bottom: -15px;
  left: -53px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
  white-space: nowrap;
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #43637c;
}

/* 
xyz
*/
.additional-options {
  padding: 0 20px 12px 20px;
  /* border-right: 1px solid #eff3f7; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.map-viewdetails {
  color: #008cde;
  width: 100%;
  max-width: '105px';
  height: '20px';
}

.map-viewdetails:hover {
  cursor: pointer;
  color: black;
}

.map-viewdetails-none {
  width: 100%;
  max-width: '105px';
  height: '20px';
  cursor: default;
}

/***********Start of Right block in Top section*****************/
/* book, weight,volume, types container */

/* .block-items-right {
    width: 203px;
    height: 228px;
    padding: 0px;
} */

.cost-sealine {
  padding: 10px 0 5px 20px;
  border-left: 1px solid #eff3f7;
}

.size-block {
  padding: 10px 15px 10px 15px;
  border-top: 1px solid #eff3f7;
  border-bottom: 1px solid #eff3f7;
  min-height: 47px;
  /* width: 100%; */
  border-left: 1px solid #eff3f7;
}

.size-block li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 15px;
  /* line-height: normal; */
}

.size-block li span:nth-child(1) {
  flex-grow: 1;
}

.booknow-savequote-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #eff3f7;
}

.savequote-container {
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.btn-savequote:hover {
  background-color: #f0fafe;
  border: 1px solid #aad8ff;
}

/* **********end of TOP section************** */

/* **********start of TOGGLE Buttom section************** */
/* map and view details toggle */
.block-items-footer {
  padding: 0px;
  width: 100%;
  max-width: 655px;
  height: auto;
}

.detailed-list {
  /* padding: 20px; */
  /* font-size: 12px; */
}

.bottom-view-details {
  font-size: 12px;
}

/* .bottom-view-details div {
  border-bottom: 2px solid transparent;
}

.bottom-view-details div:last-child {
  border-bottom: 0px solid transparent;
} */

.bottom-view-details > .bottom-view-decor {
  border-bottom: 2px solid transparent;
}

/* Pick up / delivery*/
/* Port of origin */
/* Ocean Freight */
/* Port of discharge/PortOfDischargeTable */
.my-table-21a3741 .ant-table table {
  text-align: center;
  border-radius: 0px 0px 0px 0px;
  border-collapse: collapse;
}

.my-table-21a3741 .ant-table-content > .ant-table-body > table {
  /* border: 0px; */
}

.my-table-21a3741 .ant-table-body .ant-table-thead > tr th {
  background: white;
  font-size: 10px;
  color: #43637c;
  height: 30px;
  padding: 0px;
}

.my-table-21a3741 .ant-table-thead > tr:first-child > th:last-child {
  border-radius: 0px;
}

.my-table-21a3741
  .ant-table-body
  .ant-table-thead
  > tr
  th
  > span[class='ant-table-header-column'] {
  /* color: black; */
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 4px;
  margin-left: 4px;
  /* margin-left: -20px; */
}

.my-table-21a3741 .ant-table-body .ant-table-tbody tr td {
  /* color: black; */
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  font-size: 12px;
  color: #43637c;
  height: 22px;
  padding: 4px;
}

/* align price table data cells left */
.my-table-21a3741 th.column-price,
td.column-price {
  text-align: left !important;
}

/* remove left border */

.my-table-21a3741 .ant-table-bordered .ant-table-body > table {
  border-left: 0px;
}

/* Delivery */

/* **********end of buttom************** */

/* *********************end of LEFT********************* */

/* *********************start of RIGHTEST****************** */
/* right variable arrow block */
.arrow-block {
  width: 20px;
  min-height: 100%;
  padding: 0px 5px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  background-color: #eaf9ff;
}

.arrow-block:hover {
  background-color: #d4f3ff;
  opacity: 1;
}

/* *********************end of RIGHTEST******************** */

:root {
  --blueColor: rgb(30, 148, 241);
  --redColor: rgb(250, 90, 75);
  --whiteColor: rgb(255, 255, 255);
  --searchBarHeight: 56px;
  --searchBarFontSize: 17px;
  --searchBarSectionMaxHeight: 116px;
  --searchBarSectionMinHeight: 76px;
  --searchBar: 70px;
}

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* background-color: #ECECEC; */
}

/* test */
.testT {
  border: 1px solid teal;
}

.testR {
  border: 1px solid red;
}

.testY {
  border: 1px solid yellow;
}

.testP {
  border: 1px solid purple;
}

.testO {
  border: 1px solid orange;
}

.testG {
  border: 1px solid green;
}

.testBla {
  border: 1px solid black;
}

.testBlu {
  border: 1px solid blue;
}

/* rotate button*/
.rotate-css {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.normal-css {
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  /* -webkit-transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out; */
}

.swap-button {
  position: absolute;
  /* top: 50px;
    margin-left: -20px;
    margin-top: -25px; */
  /* left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto; */
  z-index: 50;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  background-color: rgb(255, 255, 255);
  background-color: var(--whiteColor);
  color: rgb(30, 148, 241);
  color: var(--blueColor);
  top: 15px;
  left: -12px;
  padding: 3px;
  border: 0px;
}

.swap-container {
  position: relative;
}

.swap-button:hover {
  /* background-color: var(--whiteColor); */
  /* color: var(--blueColor); */
  -webkit-transform: scaleXY(1.95) !important;
          transform: scaleXY(1.95) !important;
}

/* root-container */
.root-container {
  height: auto;
  display: flex;
  flex-direction: column;
}

/* search bar */
.search-container {
  /* flex-wrap: wrap; */
  padding: 0.5rem 1rem;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  /* flex-flow: row; */
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /*font-size: var(--searchBarFontSize);
    height: var(--searchBar); */
  /* height: var(--searchBarSectionMinHeight); */
  /* border-color: rgb(36, 2, 2); */
  /* margin: auto 0; */
}

.route-block {
  flex-wrap: wrap;
  display: flex;
  /* flex-flow: row; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-size: var(--searchBarFontSize);
  height: 70px;
  height: var(--searchBar);
}

.route-block-route-types {
  display: flex;
  /* flex-flow: row; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* .route-block-route-types div>span:hover {
    background: #ff7875;
} */

.route-block-inputs {
  display: flex;
  /* flex-flow: row; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
}

.route-block-date-dropdown {
  display: flex;
  /* flex-flow: row; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* seems irrelevant css */
/* .flex-container-header {
    margin-top: 50px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
} */

.underline-on-hover:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* dropdown type of shipment */
.container-dropdown {
  height: 53px;
  width: 100%;
  border: 0px solid white;
  /* border-radius: 0px; */
  cursor: pointer;
  font-size: 15px;
  /* margin: 0px;
  padding: 0px; */
}

/* Step */

/* home */
/* background: rgba(30, 149, 242, 0.5); */
.home-search-container {
  position: fixed; 
  z-index: 100;
  padding: 10px;
  width: 100%;
  /* height: var(--searchBarSectionMaxHeight); */
  height: auto;
  /* background: var(--blueColor); */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
  /* min-height: var(--searchBarSectionMinHeight);
    max-height: var(--searchBarSectionMaxHeight); */
}

.home-result-container-new {
  margin-bottom: 20px;
  /* padding-top: 125px;  */
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
}

.home-result-container {
  margin-bottom: 20px;
  padding-top: 125px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
}

.home-result-header-card-container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

/* for no result */
.no-result {
  /* margin-bottom: 20px; */
  padding-top: 10px;
  /* background: rgb(94, 47, 47); */
}

/* for no-resultcard */
.no-result-card {
  height: 426px;
  width: 100%;
  max-width: 960px;
  border-radius: 5px;
  margin: auto;
  z-index: 50;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
}

.no-result-card-content {
  padding: 75px 0;
}

/* result card footer  */
.on-hover:hover {
  cursor: pointer;
  color: black;
}

.card-footer {
  color: blue;
  width: "120px";
}

/* lcl sliders */
.lcl-slider {
  height: 251px;
  width: 270px;
  border-radius: 5px;
  margin: auto;
  z-index: 50;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  padding: 10px 0px 10px;
  margin-bottom: 15px;
  font-size: 1rem;
  font-weight: 400;
}

.lcl-slider-heading {
  padding: 0px 0px;
  width: 100%;
  max-width: 270px;
}

/* FCL container type  */
.fcl-container-type {
  height: 196px;
  width: 270px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 0px 10px;
  margin-bottom: 15px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

/* spinner */
.spinner {
  position: fixed !important;
  top: 0.1em;
  margin-left: 10px !important;
  margin-top: 10px !important;
  height: 50px;
  left: 0.1em;
  z-index: 200;
}

/* StatusMessage */
.status_message {
  position: fixed !important;
  bottom: 1em;
  margin-left: 10px !important;
  height: auto;
  left: 1em;
  z-index: 200;
}

.status_message-background {
  background: rgba(221, 135, 22, 1);
  border-radius: 4px;
  color: white;
}

.status_message-button {
  padding-top: 4px;
  margin: 0 auto;
  width: auto;
}

.price-header-radio-btn-common {
  width: 50%;
  display: inline-block;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}


/* DASHBOARD */
/* remove close icon of map info window*/
.gm-ui-hover-effect {
  display: none !important;
}
